/* eslint-disable no-unused-vars*/
import React, { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import FormHelperText from "@mui/material/FormHelperText";
import "./style.css";
import { styled } from "@mui/material/styles";
import { ThemeContext } from "../../theme";
import { useTranslation } from "react-i18next";
import serverConnect from "../../axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const Register = () => {
    const { from: jobID } = useParams();
    const navigate = useNavigate();
    const [traduction, setLanguage] = useTranslation("global");
    const theme = useContext(ThemeContext);
    const [errorPass, setErrorPass] = useState(false);
    const [registeredModal, setRegisteredModal] = useState(false);
    const [emailTaken, setEmailTaken] = useState(false);
    const [passFormatError, setPassFormatError] = useState(false);
    const [unknownError, setUnknownError] = useState(false);
    const darkMode = theme.state.darkMode;
    const CustomButton = styled(Button)({
        color: darkMode ? "black" : "white",
        backgroundColor: darkMode ? "white" : "black",
        "&:hover": {
            backgroundColor: darkMode ? "gray" : "gray",
        },
    });

    const CustomTextField = styled(TextField)(() => ({
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: darkMode ? "white" : "black",
            },
            "&:hover fieldset": {
                borderColor: darkMode ? "white" : "black",
            },
            "&.Mui-focused fieldset": {
                borderColor: darkMode ? "white" : "black",
            },
            "& .MuiOutlinedInput-input": {
                color: darkMode ? "white" : "black",
            },
            "&.Mui-focused": {
                backgroundColor: "rgba(128, 128, 128, 0.1)",
            },
        },
        "& .MuiInputLabel-root": {
            color: darkMode ? "white !important" : "black !important",
        },
        "& .MuiInputBase-root": {
            color: darkMode ? "white" : "black",
        },
    }));

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validate passwords match
        if (e.target.password.value !== e.target.passwordRepeat.value) {
            setErrorPass(true);
            return;
        }
        if (
            !e.target.password.value.match(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*[@$!%*#?&-])[A-Za-z\d@$!%*#?&-]{8,}$/
            )
        ) {
            setPassFormatError(true);
            return;
        }

        // Send data to backend server
        const body = {
            email: e.target.email.value,
            password: e.target.password.value,
            ...(jobID ? { jobID } : {}),
        };

        try {
            const response = await serverConnect.post("/auth/register", body);
            console.log(response.statusText);
            if (response.status === 201 || response.status === 200 || response.status === 204 || response.status === 206) {
                setRegisteredModal(true);
                return; // Add return to prevent navigation
            }
        } catch (err) {
            console.log(err);
            if (err.response.data.message === "Email already taken") {
                setEmailTaken(true);
                return;
            }
            setUnknownError(true);
            return;
        }
    };

    const handleLoginRedirect = () => {
        if (jobID) navigate(`/login/${jobID}`); 
        else navigate("/login");
    };

    const successRegisterRedirect = () => {
        navigate("/login");
    };

    const clearMessages = () => {
        setEmailTaken(false);
        setErrorPass(false);
    };
    // CONFIRMED REGISTER FORM MODAL HELPERS
    const modalCloseRedirect = () => {
        setRegisteredModal(false);
        navigate("/home");
    };
    return (
        <>
            <form onSubmit={handleSubmit} onFocus={clearMessages}>
                <Stack
                    className={
                        darkMode
                            ? "register-main-layout"
                            : "register-main-layout dark-mode"
                    }
                >
                    {jobID && (
                        <>
                            <FormHelperText margin="dense" error>
                                {traduction("register.registerToApply")}
                            </FormHelperText>
                            <FormHelperText margin="dense" error>
                                {traduction("register.loginIfRegistered")}
                            </FormHelperText>
                            <br />
                            <CustomButton
                                key="back-to-login-btn"
                                margin="dense"
                                variant="contained"
                                onClick={handleLoginRedirect}
                                type="button"
                            >
                                {traduction("register.btn2")}
                            </CustomButton>
                            <br />
                            <FormHelperText margin="dense" error>
                                {traduction("register.orRegister")}
                            </FormHelperText>
                            <br />
                        </>
                    )}
                    <CustomTextField
                        name="email"
                        margin="dense"
                        className="register-text-field"
                        id="outlined-password-input-email"
                        label={traduction("register.id")}
                        type="email"
                        autoComplete="current-password"
                        variant="outlined"
                        required
                    />
                    <CustomTextField
                        name="password"
                        margin="dense"
                        className="register-text-field"
                        id="outlined-password-input-password"
                        label={traduction("register.pass")}
                        type="password"
                        autoComplete="current-password"
                        variant="outlined"
                        error={errorPass}
                        required
                    />
                    <CustomTextField
                        name="passwordRepeat"
                        margin="dense"
                        className="register-text-field"
                        id="outlined-password-input-password-repeat"
                        label={traduction("register.passRepeat")}
                        type="password"
                        // autoComplete="current-password"
                        variant="outlined"
                        error={errorPass}
                        required
                    />
                    {errorPass && (
                        <FormHelperText margin="dense" error>
                            {traduction("register.passError")}
                        </FormHelperText>
                    )}
                    {emailTaken && (
                        <FormHelperText margin="dense" error>
                            {traduction("register.emailTaken")}
                        </FormHelperText>
                    )}
                    {passFormatError && (
                        <FormHelperText margin="dense" error>
                            {traduction("register.passFormatError")}
                        </FormHelperText>
                    )}
                    {unknownError && (
                        <FormHelperText margin="dense" error>
                            {traduction("register.unknownError")}
                        </FormHelperText>
                    )}
                    <Stack spacing={2} direction="row" marginTop={2}>
                        <CustomButton
                            key="back-to-login-btn"
                            margin="dense"
                            variant="contained"
                            onClick={handleLoginRedirect}
                            type="button"
                        >
                            {traduction("register.btn2")}
                        </CustomButton>
                        <CustomButton
                            className={
                                darkMode
                                    ? "register-btn_dark-mode"
                                    : "register-btn"
                            }
                            key="register-btn"
                            margin="dense"
                            variant="contained"
                            type="submit"
                        >
                            {traduction("register.btn1")}
                        </CustomButton>
                        
                    </Stack>
                </Stack>
            </form>
            <Modal
                open={registeredModal}
                onClose={modalCloseRedirect}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box
                    className={
                        darkMode
                            ? "register_modal_box register_box_dark"
                            : "register_modal_box register_box_light"
                    }
                >
                    <h2 id="parent-modal-title">
                        {traduction("register.successTittle")}
                    </h2>
                    <p id="parent-modal-description">
                        {traduction("register.successText")}
                    </p>
                    <Button onClick={successRegisterRedirect}>
                        {traduction("register.click")}
                    </Button>
                </Box>
            </Modal>
        </>
    );
};

export default Register;
