import React, { useContext } from "react";
import { ThemeContext } from "../../theme";
import { useAuth } from "../../contexts/AuthContext";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const Profile = () => {
    const theme = useContext(ThemeContext);
    const [traduction, setLanguage] = useTranslation("global"); // eslint-disable-line
    const { logout } = useAuth(); // Add this line
    const darkMode = theme.state.darkMode;

    const CustomButton = styled(Button)({
        color: darkMode ? "black" : "white",
        backgroundColor: darkMode ? "white" : "black",
        "&:hover": {
            backgroundColor: darkMode ? "gray" : "gray",
        },
    });

    // Define the styles for centering the content
    const containerStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh", // Adjust as needed
    };

    // Define the styles for the text color based on the theme
    const textStyle = darkMode ? { color: "white" } : { color: "black" };

    return (
        <div style={containerStyle} className={
            darkMode
                ? "profile_main home_main-dark"
                : "profile_main home_main-light"
        }>
            <p style={{...textStyle, textAlign: 'center', textJustify: 'inter-word'}}>
                {traduction("profile.stayTuned")}
            </p>
            <CustomButton onClick={async () => await logout()}>{traduction("profile.logOut")}</CustomButton> {/* Add this button */}
        </div>
    );
};

export default Profile;
